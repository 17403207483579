import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

function FootBar() {
  const number = "01-5260387, 985-1022922";
  const location = "Lush road 10, Jhapa,Nepal";
  const copyRight =
    " Copyright 2022 | All Right Reserved Himalayan Lush Pvt. Ltd.";

  return (
    <Box sx={{ backgroundColor: "#E2EAED" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",

          marginTop: "5%",
          // justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "220px", height: "90px", marginTop: "30px" }}>
            <img
              src={require("../assets/BrandLogo.png")}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Typography sx={{ marginTop: "1%" }}>{number}</Typography>
          <Typography sx={{ marginTop: "1%" }}>{location}</Typography>
          {/* <Typography sx={{ marginTop: "1%", marginBottom: "2%" }}>
            {" "}
            &copy; {copyRight}
          </Typography> */}
        </Box>

        <Box
          sx={{
            // margin: "2rem",
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            flexDirection: "column",
          }}
        >
          <h2 style={{ marginTop: "5%", marginLeft: "5%" }}>About Us</h2>
          <Typography
            sx={{
              marginTop: "5%",
              marginLeft: "5%",
              width: "350px",
              textAlign: "justify",
            }}
          >
            We are a passionate and innovative company dedicated to producing
            high-quality, eco-friendly Areca leaf products that contribute to a
            sustainable future.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "45vmin",
            height: "25vmin",
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
            justifyContent: "center",
            flexDirection: "column",
            // marginLeft: "2rem",
          }}
        >
          <h2 style={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}>
            Find us:{" "}
          </h2>
          <iframe
            style={{ width: "100%", width: "100%", marginBottom: "2rem" }}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14257.232249496656!2d88.0349728!3d26.7026037!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xde6ae8fa92b875dd!2sHimalayan%20Lush%20Industry!5e0!3m2!1sen!2snp!4v1674727453166!5m2!1sen!2snp"
          ></iframe>
        </Box>
      </Container>

      <Box
        style={{
          backgroundColor: "#0293DE",
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Typography> &copy; {copyRight}</Typography>
      </Box>
    </Box>
  );
}

export default FootBar;
