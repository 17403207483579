import { Box, Typography } from "@mui/material";
import React from "react";

function TitleTextBox({ title, subtitle }) {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: " #99C386",
        height: "20vmin",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "white",
            fontSize: { xs: "20px", sm: "20px", md: "30px", lg: "40px" },
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "55vmin", sm: "55vmin", md: "50vmin", lg: "50vmin" },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "white",
            fontSize: { xs: "10px", sm: "12px", md: "16px", lg: "18px" },
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

export default TitleTextBox;
