import React from 'react'
import Marquee from "react-fast-marquee";
import { ParallerXData } from '../../Data/parallerXData'
import { Box, Typography } from '@mui/material';
function ParallerX() {
  return (
    <Box sx={{backgroundColor:"#81C5DD" }}>
      <Marquee 
      speed={60}
      >
        {ParallerXData.map((data) => {
          return (
            <Box sx={{ marginLeft: "20px", padding:"20px" }}>
              <Typography> {data.name}</Typography>
            </Box>

          );
        })}
      </Marquee>
    </Box>
  )
}

export default ParallerX