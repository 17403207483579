export const JourneyData = [
    {
        "id":1,
        "title" :"Collection",
        "imageUrl":"./process/process1.jpeg",
        "subject":"Collection of Aereca Leaves/Sheaths which are naturally dropped by the plant over a period of time."
    },
    {
        "id":2,
        "title" :"Storage",
        "imageUrl":"./process/process2.jpg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },
    {
        "id":3,
        "title" :"Soaking",
        "imageUrl":"./process/process3.jpeg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },
    {
        "id":4,
        "title" :"Cleaning",
        "imageUrl":"./process/process4.jpeg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },
    {
        "id":5,
        "title" :"Drying",
        "imageUrl":"./process/process5.jpeg",
        "subject":"Collection of Aereca Leaves/Sheaths which are naturally dropped by the plant over a period of time."
    },
    {
        "id":6,
        "title" :"Production",
        "imageUrl":"./process/process6.jpeg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },
    {
        "id":7,
        "title" :"UV Treatment",
        "imageUrl":"./process/process7.jpeg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },
    {
        "id":8,
        "title" :"Packaging",
        "imageUrl":"./process/process8.jpeg",
        "subject":"The Leaves/Sheath are then stored a sunny and dry shed for further processing."
    },


]