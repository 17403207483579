import { Container } from '@mui/system'
import React from 'react'
import ContactForm from '../Components/Forms/ContactForm'
import MapBox from '../Components/UI/MapBox'
import { Box } from '@mui/system'
function ContactUsPage() {
  return (
    <>
      <Box sx={{display:'flex', gap:"10%" , flexWrap:"wrap" , justifyContent:"center" , margin:"5%"}}>
        <Box sx={{ position:"relative " , marginBottom:"10%"}}>
          <ContactForm />

        </Box>
        <Box sx={{width:"50vmin"}}>
          <MapBox />

        </Box>
      </Box>


    </>
  )
}

export default ContactUsPage