import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

function HomeAboutPage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "5%",
        flexWrap: "wrap",
        gap: "30px",
        backgroundColor: "#cecece",
        padding: "2rem",
        borderRadius: "10px",
      }}
    >
      <Paper elevation={3}>
        <Box sx={{ height: "40vmin", overflow: "hidden" }}>
          <img src={"aboutus1.png"} style={{ width: "100%", height: "100%" }} />
        </Box>
      </Paper>
      <Box sx={{ sm: { marginTop: "20px" } }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "30px" },
          }}
        >
          OUR CUSTOMERS COME FIRST!
        </Typography>
        <Typography
          sx={{
            width: "55vmin",
            textAlign: "justify",
            marginTop: "5%",
            fontSize: { xs: "15px", sm: "15px", md: "16px", lg: "18px" },
          }}
        >
          At Himalayan Lush, we prioritize our customers. As a leading producer
          of eco-friendly Areca leaf products, we are committed to delivering
          high-quality, biodegradable alternatives that promote sustainability.
          With a customer-centric approach, we meet our customers' expectations
          and actively listen to their feedback. By choosing Himalayan Lush,
          customers contribute to a greener future and join a community
          dedicated to positive environmental impact.
          <Link
            to={"/aboutus"}
            style={{
              textDecoration: "none",
            }}
          >
            Read more ...
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default HomeAboutPage;
