export const ProductData = [
  {
    id: 1,
    title: '4" Ice Cream Spoon',
    imgMain: "IcecreamSpoon.jpg",
    img: [
      {
        id: 1,
        imgUrl: "IcecreamSpoon.jpg",
      },
      {
        id: 2,
        imgUrl: "IcecreamSpoon.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 2,
    title: "4'' Round Bowl",
    imgMain: "4'' Round Bowl.jpg",
    img: [
      {
        id: 1,
        imgUrl: "4'' Round Bowl.jpg",
      },
      {
        id: 2,
        imgUrl: "4'' Round Bowl.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 3,
    title: "5.5 Container",
    imgMain: "5.5 Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "5.5 Container.jpg",
      },
      {
        id: 2,
        imgUrl: "5.5 Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 4,
    title: "5'' Round Plate (GS)",
    imgMain: "5'' Round Plate (GS).jpg",
    img: [
      {
        id: 1,
        imgUrl: "5'' Round Plate (GS).jpg",
      },
      {
        id: 2,
        imgUrl: "5'' Round Plate (GS).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 5,
    title: "7.5'' Round Container",
    imgMain: "7.5'' Round Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "7.5'' Round Container.jpg",
      },
      {
        id: 2,
        imgUrl: "7.5'' Round Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 6,
    title: "5'' Round Plate (NL)",
    imgMain: "5'' Round Plate (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "5'' Round Plate (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "5'' Round Plate (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 7,
    title: "5.5'' Round Container",
    imgMain: "5.5'' Round Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "5.5'' Round Container",
      },
      {
        id: 2,
        imgUrl: "5.5'' Round Container",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 8,
    title: "5.5'' Soup Spoon",
    imgMain: "5.5'' Soup Spoon.jpg",
    img: [
      {
        id: 1,
        imgUrl: "5.5'' Soup Spoon.jpg",
      },
      {
        id: 2,
        imgUrl: "5.5'' Soup Spoon.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 9,
    title: "5.5'' Spoon Medium",
    imgMain: "5.5'' Spoon Medium.jpg",
    img: [
      {
        id: 1,
        imgUrl: "5.5'' Spoon Medium.jpg",
      },
      {
        id: 2,
        imgUrl: "5.5'' Spoon Medium.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 10,
    title: "5.5'' Spoon Small",
    imgMain: "5.5'' Spoon Small.jpg",
    img: [
      {
        id: 1,
        imgUrl: "5.5'' Spoon Small.jpg",
      },
      {
        id: 2,
        imgUrl: "5.5'' Spoon Small.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 11,
    title: "6'' Round Plate (NL)",
    imgMain: "6'' Round Plate (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Round Plate (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Round Plate (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 12,
    title: "7.5'' Round Container",
    imgMain: "7.5'' Round Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "7.5'' Round Container.jpg",
      },
      {
        id: 2,
        imgUrl: "7.5'' Round Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 13,
    title: "6'' Round Plate CIR (NL)",
    imgMain: "6'' Round Plate CIR (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Round Plate CIR (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Round Plate CIR (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 14,
    title: "6 Square Container",
    imgMain: "6 Square Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "6 Square Container.jpg",
      },
      {
        id: 2,
        imgUrl: "6 Square Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 15,
    title: "6'' Square Container",
    imgMain: "6'' Square Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Square Container.jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Square Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 16,
    title: "6'' Square Flat Plate (NL)",
    imgMain: "6'' Square Flat Plate (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Square Flat Plate (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Square Flat Plate (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 17,
    title: "6'' Square Plate",
    imgMain: "6'' Square Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Square Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Square Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },

  {
    id: 19,
    title: "6'' Tongue Depresser",
    imgMain: "6'' Tongue Depresser.jpg",
    img: [
      {
        id: 1,
        imgUrl: "6'' Tongue Depresser.jpg",
      },
      {
        id: 2,
        imgUrl: "6'' Tongue Depresser.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 20,
    title: "6''x3'' HotDog Plate.jpg",
    imgMain: "6''x3'' HotDog Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "6''x3'' HotDog Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "6''x3'' HotDog Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 21,
    title: "7'' Square Plate (NL)",
    imgMain: "7'' Square Plate (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "7'' Square Plate (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "7'' Square Plate (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 22,
    title: "7'' Triangler Plate",
    imgMain: "7'' Triangler Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "7'' Triangler Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "7'' Triangler Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 23,
    title: "7.5 Container",
    imgMain: "7.5 Container.jpg",
    img: [
      {
        id: 1,
        imgUrl: "7.5 Container.jpg",
      },
      {
        id: 2,
        imgUrl: "7.5 Container.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 24,
    title: "8'' Round Plate (CIR)",
    imgMain: "8'' Round Plate (CIR).jpg",
    img: [
      {
        id: 1,
        imgUrl: "8'' Round Plate (CIR).jpg",
      },
      {
        id: 2,
        imgUrl: "8'' Round Plate (CIR).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 25,
    title: "9'' Round Plate (NL)",
    imgMain: "9'' Round Plate (NL).jpg",
    img: [
      {
        id: 1,
        imgUrl: "9'' Round Plate (NL).jpg",
      },
      {
        id: 2,
        imgUrl: "9'' Round Plate (NL).jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 26,
    title: "9 Round plate",
    imgMain: "9 Round plate2.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9 Round plate2.jpg",
      },
      {
        id: 2,
        imgUrl: "9 Round plate2.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 27,
    title: "9'' Square Plate",
    imgMain: "9'' Square Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9'' Square Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "9'' Square Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 28,
    title: "9.5'' Round 3 CP Plate",
    imgMain: "9.5'' Round 3 CP Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9.5'' Round 3 CP Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "9.5'' Round 3 CP Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 29,
    title: "9.5'' Square 3 CP Plate",
    imgMain: "9.5'' Square 3 CP Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9.5'' Square 3 CP Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "9.5'' Square 3 CP Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 30,
    title: "9''Round Plate",
    imgMain: "9''Round Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9''Round Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "9''Round Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 31,
    title: "9''x5.5 Ovel Plate",
    imgMain: "9''x5.5 Ovel Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "9''x5.5 Ovel Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "9''x5.5 Ovel Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 32,
    title: "10''x8'' Rectangular 5 CP plate",
    imgMain: "10''x8'' Rectangular 5 CP plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "10''x8'' Rectangular 5 CP plate.jpg",
      },
      {
        id: 2,
        imgUrl: "10''x8'' Rectangular 5 CP plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 33,
    title: "11_D Round Plate",
    imgMain: "11_D Round Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "11_D Round Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "11_D Round Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 34,
    title: "10 Round Plate d",
    imgMain: "10 Round Plate d.jpeg",
    img: [
      {
        id: 1,
        imgUrl: "10 Round Plate d.jpeg",
      },
      {
        id: 2,
        imgUrl: "10 Round Plate d.jpeg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 35,
    title: "10_D Round Plate",
    imgMain: "10_D Round Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "10_D Round Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "10_D Round Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 36,
    title: "10''x7.5'' Boat Shape Tray",
    imgMain: "10''x7.5'' Boat Shape Tray.jpg",
    img: [
      {
        id: 1,
        imgUrl: "10''x7.5'' Boat Shape Tray.jpg",
      },
      {
        id: 2,
        imgUrl: "10''x7.5'' Boat Shape Tray.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
  {
    id: 37,
    title: "10''x7'' Rectangular Plate",
    imgMain: "10''x7'' Rectangular Plate.jpg",
    img: [
      {
        id: 1,
        imgUrl: "10''x7'' Rectangular Plate.jpg",
      },
      {
        id: 2,
        imgUrl: "10''x7'' Rectangular Plate.jpg",
      },
    ],
    price: "",
    info: "comming soon...",
  },
];