import React from 'react'
import TopLogo from './TopLogo'
import ParallerX from './ParallerX'
import LinkBar from './LinkBar'


function NavBar() {
  return (
    <>
      <TopLogo />
      <ParallerX />
      <LinkBar/>

    </>
  )
}

export default NavBar