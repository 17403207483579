import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ProductBox from "../Components/UI/ProductBox";
import TitleTextBox from "../Components/UI/TitleTextBox";

function ProductPage() {
  const title = "OUR PRODUCTS";
  const subtitle =
    "Our products at Himalayan Lush offer sustainable and eco-friendly solutions made from premium Areca leaves, promoting a greener future.";
  return (
    <Box>
      <TitleTextBox title={title} subtitle={subtitle} />
      <Container>
        <ProductBox />
      </Container>
    </Box>
  );
}

export default ProductPage;
