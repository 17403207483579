import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function JourneyProduct({ id, imgUrl, title, subject }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginLeft: "2rem",
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          backgroundColor: "gray",
          width: { xs: "70vmin", sm: "40vmin", md: "40vmin", lg: "40vmin" },
          height: { xs: "70vmin", sm: "40vmin", md: "40vmin", lg: "40vmin" },
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img
          src={`/${imgUrl}`}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "65vmin", sm: "65vmin", md: "40vmin", lg: "40vmin" },
          height: "20vmin",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "18px", sm: "18px", md: "20px", lg: "22px" },
          }}
        >
          {id}. {title}
        </Typography>
        <Typography
          variant="h8"
          sx={{
            textAlign: "center",
            fontSize: { xs: "15px", sm: "15px", md: "16px", lg: "18px" },
          }}
        >
          {subject}
        </Typography>
      </Box>
    </Box>
  );
}

export default JourneyProduct;
