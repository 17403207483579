import { AppBar, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import leftSide from "../../assets/leftLeaf.png";

function TopLogo() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignContent: "flex-start",
        }}
      >
        <Box
          sx={{
            width: { xs: "10vmin", sm: "10vmin", md: "10vmin", lg: "10vmin" },
          }}
        >
          <img
            src={require("../../assets/leftLeaf.png")}
            className="leftLeaf"
          />
        </Box>
        <Box
          sx={{
            width: { xs: "20vmin", sm: "20vmin", md: "20vmin", lg: "20vmin" },
          }}
        >
          <img
            src={require("../../assets/BrandLogo.png")}
            className="BrandLogo"
          />
        </Box>
        <Box>
          <img
            src={require("../../assets/RightLeaf.png")}
            className="RightLeaf"
          />
        </Box>
      </AppBar>
    </Box>
  );
}

export default TopLogo;
