import React from 'react'
import { Route, Routes } from "react-router-dom"
import './App.css';
import NavBar from './Components/AppBar/NavBar';
import HomePage from './Pages/HomePage';
import { Box } from '@mui/system';
import AboutPage from './Pages/AboutPage';
import ContactUsPage from './Pages/ContactUsPage';
import ProductPage from './Pages/ProductPage';
import ProductDetailPages from './Pages/ProductDetailPages';
import FootBar from './Components/FootBar';
import { createTheme , ThemeProvider } from '@mui/material';

const theme = createTheme({
  typography :{
   fontFamily : [
     'Poppins', 'sans-serif',
   ]
  },
})
function App() {
  return (
    <ThemeProvider theme={theme}>
    <Box className="App">
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/products' element={<ProductPage/>} />
        <Route path='/aboutus' element={<AboutPage/>} />
        <Route path='/contactus' element={<ContactUsPage/>} />
        <Route path='/products/:productId' element={<ProductDetailPages/>} />
      </Routes>
      <FootBar/>
    </Box>
    </ThemeProvider>
  );
}

export default App;
