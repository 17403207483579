import React from "react";
import { Box } from "@mui/system";

function Circular() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      style={{ backgroundImage: "url()" }}
    >
      <img src={"cirularImage.png"} className="Plate-logo" />
    </Box>
  );
}

export default Circular;
