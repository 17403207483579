export const NavData = [
    {
        "name" : "Home",
        "path":"/"
    },
    {
        "name" : "Products",
        "path":"/products"
    },
    {
        "name" : "About Us",
        "path":"/aboutus"
    },
    {
        "name" : "Contact Us",
        "path":"/contactus"
    },

]