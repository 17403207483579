import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { Button, Grid } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Alert from "@mui/material/Alert";

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_aha0hn6",
        "template_ly1mc5e",
        form.current,
        "lP4crp-qS0GZHP_nB"
      )
      .then(
        (result) => {
          alert("Email sent Sucessfully");
        },
        (error) => {
          console.log(error.text);
          alert(error.text);
        }
      );
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    setEmail("");
    setPassword("");
  }

  return (
    <Box
      sx={{
        width: "400px",
        margin: "5%",
        position: "relative",
        backgroundColor: "white",
        padding: "2rem",
      }}
    >
      <form ref={form} onSubmit={sendEmail}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <TextField
              autoComplete="fname"
              name="user_name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Name"
              autoFocus
              sx={{ color: "#99C386", backgroundColor: "#F5F5F5" }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="user_email"
              autoComplete="email"
              sx={{ color: "#99C386", backgroundColor: "#F5F5F5" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="subject"
              label="Subject"
              name="user_subject"
              autoComplete="email"
              sx={{ color: "#99C386", backgroundColor: "#F5F5F5" }}
            />
          </Grid>
          <Grid item>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Message"
              style={{ width: 400, height: 100 }}
              name="message"
              sx={{ color: "#99C386", backgroundColor: "#F5F5F5" }}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{ backgroundColor: "#99C386", width: "150px" }}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default ContactForm;
