import { Button, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import { ProductData } from '../../Data/productData'

function ProductBox() {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "2%" }}>
      {ProductData.map((prodData) => {
        return (
          <Paper sx={{ margin: "20px", backgroundColor: "#99C386", display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "40vmin", height: "30vmin", overflow: "hidden", margin: "15px" }}>
              
              <img src={require(`../../assets/${prodData.imgMain}`)} style={{ width: "100%", height: "100%" }} />
            </Box>
            <Typography sx={{ textAlign: "center", margin: "4px" }}>  {prodData.title}</Typography>
            <Button sx={{ margin: "20px", backgroundColor: "white" }}><Link to={`/products/${prodData.id}`}
              style={{
                textDecoration: "none",
                color: "black"

              }}
            >SEE MORE..</Link></Button>

          </Paper>

        )
      })}


    </Box>
  )
}

export default ProductBox