import { Link, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';

function MapBox() {
  const phoneNumber = "023-555522"
  const email = "himalayanlush@gmail.com"
  const website = "www.himalayanlush.com"
  const location ="Lush Road, Setipani, Arjundhara-10, Jhapa"
  return (
    <>
      <Paper elevation={3} sx={{padding:"5px" , margin:"2%", width:"60vmin" } }>
        <Box sx={{width:"60vmin" , height:"25vmin" , display:"flex" , justifyContent:'center'}}>
          <iframe  style={{width:"100%" , height:"100%"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14257.232249496656!2d88.0349728!3d26.7026037!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xde6ae8fa92b875dd!2sHimalayan%20Lush%20Industry!5e0!3m2!1sen!2snp!4v1674727453166!5m2!1sen!2snp"  ></iframe>
        </Box>
        <Box sx={{margin:"5%"}}>
          <Typography variant='h6' sx={{margin:"5px"}}>Contacts</Typography>
          <Typography variant='h9' sx={{
            marginLeft:"10px", textAlign:"justify" 
          }}>{location}</Typography>
          <Box sx={{display:"flex" , alignContent:"center" , margin:'10px' }}><PhoneIcon fontSize="small"/> <Typography sx={{marginLeft:"10px"}}>{phoneNumber}</Typography></Box>
          <Box sx={{display:"flex" , alignContent:"center" , margin:'10px' }}><EmailIcon fontSize="small"/> <Typography sx={{marginLeft:"10px"}}>{email}</Typography></Box>
          <Box sx={{display:"flex" , alignContent:"center" , margin:'10px' }}><LanguageIcon fontSize="small"/> <Typography sx={{marginLeft:"10px"}}>{website}</Typography></Box>
          
          
        </Box>
        <Box sx={{display:'flex', justifyContent:"center" , margin:"10px"}}>
        <Link href='https://www.google.com/'><FacebookIcon fontSize="Large"/></Link>
        </Box>
  
       
      </Paper>
    </>
  )
}

export default MapBox