import React from 'react'
import { Box } from '@mui/system'
import { useParams } from 'react-router-dom'
import { ProductData } from '../../Data/productData'
import { SideBySideMagnifier } from 'react-image-magnifiers';
import { Paper } from '@mui/material';

function ProductDetauksImage() {
  const params = useParams();
  const productId = params.productId
  return (
    <Box sx={{
      display:"flex",
      flexDirection:"column",
      

    }}>
      {ProductData.map((producdata) => {
        return (
          <Box >
            {producdata.id == productId ?

              <Box >
                <Paper sx={{
                  display:"block",
                  overflow: "hidden",
                  width:"65vmin",
                  height: "40vmin",
                  marginBottom:"5%",
                  
                }}>
                  <img src={require(`../../assets/${producdata.imgMain}`)} style={{
                    width: "100%",
                    height: "100%"
                  }} />
                </Paper>
                <Box sx={{ display: "flex", justifyContent: "center", marginTop:"2%" ,  width:"65vmin"}}>
                  {producdata.img.map((imgdata) => {
                    return (
                      <Paper sx={{
                        display:"block",
                        overflow: "hidden",
                        width:"25vmin",
                        height: "15vmin",
                        backgroundColor: "blue",
                        margin: "4px"

                      }}>
                        <img src={require(`../../assets/${imgdata.imgUrl}`)} style={{
                          width: "100%",
                          height: "100%"
                        }} />

                      </Paper>

                    )
                  })}

                </Box>
              </Box>

              :
              <></>}

          </Box>
        )
      })}

    </Box>
  )
}

export default ProductDetauksImage