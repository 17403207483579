import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { NavData } from "../../Data/navData";
import { NavLink } from "react-router-dom";

function LinkBar() {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#F1FFEA",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {NavData.map((nav) => {
        return (
          <Box sx={{ paddingLeft: "40px" }}>
            <NavLink
              to={nav.path}
              style={({ isActive }) => ({
                color: isActive ? "#81C5DD" : "#545e6f",
                background: isActive ? "#F1FFEA" : "#F1FFEA",
                textDecoration: "none",
                display: "block",
                padding: "16px",
              })}
            >
              <Typography
                variant="h5"
                sx={{
                  ":hover": {
                    color: "#81C5DD",
                  },
                  fontWeight: "bold",
                  fontSize: { xs: "10px", sm: "12px", md: "16px", lg: "18px" },
                }}
              >
                {nav.name}
              </Typography>
            </NavLink>
          </Box>
        );
      })}
    </Box>
  );
}

export default LinkBar;
