import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AboutImage from "../Components/UI/AboutImage";
import TitleTextBox from "../Components/UI/TitleTextBox";

function AboutPage() {
  const title = "WHO WE ARE?";
  const subtitle =
    "We are a passionate and innovative company dedicated to producing high-quality, eco-friendly Areca leaf products that contribute to a sustainable future.";
  const conatinTitle = "OUR CUSTOMERS COME FIRST!";
  const details =
    "At Himalayan Lush, our customers are our top priority. We are a leading producer of eco-friendly Areca leaf products, committed to providing high-quality, biodegradable alternatives that promote sustainability. With a customer-centric approach, we focus on delivering products that meet our customers' expectations in terms of quality, functionality, and environmental responsibility. We actively listen to customer feedback, continuously improve our offerings, and ensure exceptional service. By choosing Himalayan Lush, customers contribute to a greener future and join a community dedicated to making a positive impact on the environment.";
  return (
    <div
      style={{
        backgroundColor: "white",
        marginBottom: "-4.8rem",
        paddingBottom: "3rem",
      }}
    >
      <TitleTextBox title={title} subtitle={subtitle} />
      <AboutImage />
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        {conatinTitle}
      </Typography>
      <Container sx={{ marginTop: "20px" }}>
        <Typography sx={{ textAlign: "justify", margin: { xs: "20px" } }}>
          {details}
        </Typography>
      </Container>
    </div>
  );
}

export default AboutPage;
