import React from "react";
import { HomeImageData } from "../../Data/homeImageData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";

function SliderImage() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box>
      <Slider {...settings}>
        {HomeImageData.map((imgdata) => {
          return (
            <Box
              sx={{
                height: "80vmin",
                overflow: "hidden",
              }}
            >
              <img
                src={require(`../../assets/${imgdata.homeImg}`)}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

export default SliderImage;
