import { Box } from "@mui/system";
import React from "react";
import ProductDetailText from "../Components/UI/ProductDetailText";
import ProductDetauksImage from "../Components/UI/ProductDetauksImage";
import TitleTextBox from "../Components/UI/TitleTextBox";

function ProductDetailPages() {
  const title = "WHO WE ARE?";
  const subtitle =
    "We are a passionate and innovative company dedicated to producing high-quality, eco-friendly Areca leaf products that contribute to a sustainable future.";
  return (
    <Box>
      <TitleTextBox title={title} subtitle={subtitle} />
      <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
        <Box sx={{ margin: "4%" }}>
          {" "}
          <ProductDetauksImage />
        </Box>
        <Box sx={{ margin: "4%" }}>
          {" "}
          <ProductDetailText />{" "}
        </Box>
      </Box>
    </Box>
  );
}

export default ProductDetailPages;
