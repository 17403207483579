import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";
import { ProductData } from "../../Data/productData";

function ProductDetailText() {
  const params = useParams();
  const productId = params.productId;
  return (
    <Box sx={{ backgroundColor: "#F1FFEA", padding: "5rem" }}>
      {ProductData.map((proddata) => {
        return (
          <>
            {proddata.id == productId ? (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h5">{proddata.title}</Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      marginTop: "5%",
                    }}
                  >
                    RS. {proddata.price}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "5%",
                    }}
                  >
                    Product Information
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "justify",
                      width: "50vmin",
                      marginTop: "5%",
                    }}
                  >
                    {proddata.info}
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
          </>
        );
      })}
    </Box>
  );
}

export default ProductDetailText;
