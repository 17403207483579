import { Box } from '@mui/system'
import React from 'react'
import ForwardIcon from '@mui/icons-material/Forward';

function JourneySideArrow() {
    return (
        <Box sx={{ display: "flex" , alignItems:"center" }}>
            <Box>
                <hr style={{
                    border: "3px solid #7EC3DC",
                    width: "70px",
                    borderRadius: "5px",
                }} />
            </Box>

            <ForwardIcon sx={{
                color: "#7EC3DC",
                width: "70px",
                height: "100px"

            }} />
             <Box>
                <hr style={{
                    border: "3px solid #7EC3DC",
                    width: "70px",
                    borderRadius: "5px",
                }} />
            </Box>
        </Box>
    )
}

export default JourneySideArrow