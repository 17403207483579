import { Container } from "@mui/system";
import React from "react";
import Circular from "../Components/UI/Circular";
import HomeAboutPage from "../Components/UI/HomeAboutPage";
import Journey from "../Components/UI/Journey";
import SliderImage from "../Components/UI/SliderImage";
import TitleTextBox from "../Components/UI/TitleTextBox";

function HomePage() {
  const title = "WHO WE ARE?";
  const subtitle =
    "We are a passionate and innovative company dedicated to producing high-quality, eco-friendly Areca leaf products that contribute to a sustainable future.";

  return (
    <div
      style={{
        backgroundColor: "white",
        paddingBottom: "4rem",
        marginBottom: "-5rem",
      }}
    >
      <SliderImage />
      <Circular />
      <TitleTextBox title={title} subtitle={subtitle} />
      <Container>
        <HomeAboutPage />
      </Container>
      <Container sx={{ marginTop: "20vmin" }}>
        <Journey />
      </Container>
    </div>
  );
}

export default HomePage;
