import React from 'react'
import { Box } from '@mui/system'
import { Paper } from '@mui/material'
function AboutImage() {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "2%" }}>
        <Paper sx={{
          height: "40vmin",
          padding: "10px",
          margin: "20px"


        }}><img src='aboutus1.png' style={{
          width: "100%",
          height: "100%",


        }} /></Paper>
        <Paper sx={{
          height: "40vmin", padding: "10px", margin: "20px"
        }}><img src='aboutus2.png' style={{
          width: "100%",
          height: "100%",

        }} /></Paper>
      </Box>


    </Box>
  )
}

export default AboutImage