export const ParallerXData = [
    {
        "name" : "नमस्ते"
    },
    {
        "name" : "你好"
    },
    {
        "name" : "Bonjour"
    },
    {
        "name" : "こんにちは"
    },
    {
        "name" : "Hola"
    },
    {
        "name" : "Hello"
    },
    {
        "name" : "Ciao"
    },
    {
        "name" : "Ola"
    },
    {
        "name" : "Privet"
    },
    {
        "name" : "Annyeonghaseyo"
    },
    {
        "name" : "مرحبًا"
    },

    

]

