import { Box } from "@mui/system";
import React, { useRef } from "react";
import JourneyProduct from "./JourneyProduct";
import ScrollContainer from "react-indiana-drag-scroll";
import JourneySideArrow from "./JourneySideArrow";
import { JourneyData } from "../../Data/journeyData";
import { Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Journey(props) {
  const sliderRef = useRef(null); // Create a reference for the Slider component

  // Function to automatically move to the next slide
  const nextSlide = () => {
    sliderRef.current.slickNext(); // Call the slickNext() method to go to the next slide
  };

  var settings = {
    autoplay: true,
    autoplaySpeed: 5000, // Set the duration (in milliseconds) for each slide
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 0,
          slidesToScroll: 0,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingRight: "1rem",
        paddingLeft: "1rem",
        borderRadius: "10px",
        backgroundColor: "#cecece",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginTop: "50px",
          marginBottom: "50px",
          color: "#5B8947",
          paddingTop: "1rem",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
        }}
      >
        THE JOURNEY OF OUR PRODUCTS
      </Typography>
      <Slider
        ref={sliderRef}
        {...settings}
        style={{
          color: "black",
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
          },
        }}
      >
        {JourneyData.map((journey, index) => (
          <React.Fragment key={index}>
            <JourneyProduct
              imgUrl={journey.imageUrl}
              title={journey.title}
              id={journey.id}
              subject={journey.subject}
            />
            {index >= JourneyData.length - 1 ? <></> : ""}
          </React.Fragment>
        ))}
      </Slider>
    </Box>
  );
}

export default Journey;
